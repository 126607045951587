import React, { useState } from "react";
import "./index.css";
import logo from "./logo.png"; // Make sure to import the logo image
import userImage from "./user.jpg"; // Make sure to import the user image

import {
  Box,
  Drawer,
  Grid,
  hexToRgb,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import comingSoon from "../../assets/images/coming-soon.jpg";
import { defaultLabelColors, spacing } from "utils/constants";
import iconOne from "../../assets/images/FrankIcons/livraison.png";
import iconTwo from "../../assets/images/FrankIcons/economique.png";
import iconThree from "../../assets/images/FrankIcons/assuree.png";
import CustomButton from "ui-components/CustomButton";
import imageParticular from "../../assets/images/FrankIcons/particlular.jpg";
import imageProfessional from "../../assets/images/FrankIcons/professional.jpg";
import imageBannerTwo from "../../assets/images/FrankIcons/mobile-screens-steps.png";
import trainImage from "../../assets/images/FrankIcons/trainImage.jpg";
import boxImage from "../../assets/images/FrankIcons/boxImage.jpg";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { AppStoreFR, FrankIcon } from "assets/images/FrankIcons";
import frankWhiteLogo from "../../assets/images/white-logo-frank.png";
import MenuIcon from "@mui/icons-material/Menu";
import bottomAction from "../../assets/images/FrankIcons/bottom-action.png";
import { useNavigate } from "react-router-dom";
import googlePlayFrench from "../../assets/images/google-play-store-french.png";
import { ArrowCircleRight, ArrowForward } from "@mui/icons-material";
import homeBanner from "../../assets/images/FrankIcons/home-banner.png";
import homeBannerMobile from "../../assets/images/FrankIcons/home-banner-mobile.png";
import arrow from "../../assets/images/FrankIcons/arrow-dots.png";
import MobileView from "./MobileView";
import appStoreFrench from "../../assets/images/FrankIcons/app-store.png";
import useStyles from "routes/style-jss";
import zIndex from "@mui/material/styles/zIndex";
import NewHeader from "ui-components/NewHeader";

function LandingPage(props: any) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [dropdown1Open, setDropdown1Open] = useState(false);
  const [dropdown2Open, setDropdown2Open] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDropdown1 = () => {
    setDropdown1Open(!dropdown1Open);
    if (dropdown2Open) setDropdown2Open(false);
  };

  const toggleDropdown2 = () => {
    setDropdown2Open(!dropdown2Open);
    if (dropdown1Open) setDropdown1Open(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeDropdowns = () => {
    setDropdown1Open(false);
    setDropdown2Open(false);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    closeDropdowns();
  };

  const menuItems = [
    { text: "Accueil", link: "/" },
    { text: "Particulier", link: "/" },
    { text: "Transporteur", link: "/" },
    { text: "Déménagement", link: "/" },
    { text: "Market place", link: "/" },
    { text: "Stockage colis", link: "/" },
    { text: "Blog", link: "/" },
  ];

  return (
    <>
      <div className="web-screen">
      <Grid
          container
          spacing={2}
          className="display-flex"
          style={{ background: "#ee6931",paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop:'30px'}}
        >
          <Grid xs={12} item style={{ zIndex: 99  }}>
            <NewHeader />
          </Grid>
        </Grid>

        <Grid container spacing={spacing?.spacing2} style={{ height: "100%" }}>
          <Grid xs={12} item style={{ paddingTop: 100 }}>
            <div className="main-div">
              <div className="home-banner-inner-div">
                <p className="home-banner-inner-text">
                  LE CHOIX
                  <br />
                  D'UNE LIVRAISON
                  <br />
                  <span
                    style={{ color: "#f9d9b6", fontFamily: "Raleway-Italic" }}
                  >
                    QUI VOUS RESSEMBLE!
                  </span>
                </p>
                <p
                  style={{
                    fontFamily: "Raleway-Italic",
                    fontSize: "22px",
                    color: "#fff",
                  }}
                >
                  Envoyez des colis à prix réduits, de maniére
                  <br />
                  éco-responsble et découvrez toute une
                  <br />
                  communauté.
                </p>
                <div
                  style={{ marginTop: "20px" }}
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  <CustomButton
                    value={"DÉCOUVREZ QUI NOUS SOMMES"}
                    endIcon={
                      <ArrowCircleRight
                        style={{ color: "#f9d9b6", fontSize: "32px" }}
                        onClick={() => {
                          navigate("/about-us");
                        }}
                      />
                    }
                    style={{
                      background: "#3026f1",
                      color: "#fff",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "700px",
                      borderRadius: "8px",
                      fontSize: "16px",
                      height: "55px",
                      zIndex: "111111",
                      cursor: "pointer",
                    }}
                    classesNames={""}
                    onClick={() => {
                      navigate("/about-us");
                    }}
                  />
                </div>
              </div>
              <img
                src={homeBanner}
                alt="Web Banner"
                style={{
                  width: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 60,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: -1,
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "170px",
          }}
        >
          <Grid xs={12} sm={6} md={3} item style={{ maxWidth: "300px" }}>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontWeight: "900",
                fontSize: "45px",
                color: "#3026f1",
              }}
            >
              POURQUOI
              <br />
              CHOISIR
              <br />
              <span style={{ color: "#ee6931", fontFamily: "Raleway-Italic" }}>
                FRANK
              </span>{" "}
              ?
            </p>
            <span>
              <img src={arrow} alt="arrow" />
            </span>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
            item
            style={{ position: "relative", maxWidth: "300px" }}
          >
            <div
              style={{
                background: "#f9d9b6",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "200px",
              }}
            >
              <img
                src={iconOne}
                style={{ height: "130px", position: "absolute", top: -70 }}
                alt=""
              />
              <div
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "18px",
                  fontWeight: "900",
                  color: "#ee6931",
                }}
              >
                <span style={{ color: "#3026f1" }}>LIVRAISON</span>
                <br />
                ÉCOLOGIQUE
              </div>
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
            item
            style={{ position: "relative", maxWidth: "300px" }}
          >
            <div
              style={{
                background: "#f9d9b6",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "200px",
              }}
            >
              <img
                src={iconTwo}
                style={{ height: "130px", position: "absolute", top: -70 }}
                alt=""
              />
              <div
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "18px",
                  fontWeight: "900",
                  color: "#ee6931",
                }}
              >
                <span style={{ color: "#3026f1" }}>LIVRAISON</span>
                <br />
                ÉCOLOGIQUE
              </div>
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
            item
            style={{ position: "relative", maxWidth: "300px" }}
          >
            <div
              style={{
                background: "#f9d9b6",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "200px",
              }}
            >
              <img
                src={iconThree}
                style={{ height: "130px", position: "absolute", top: -70 }}
                alt=""
              />
              <div
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "18px",
                  fontWeight: "900",
                  color: "#ee6931",
                }}
              >
                <span style={{ color: "#3026f1" }}>LIVRAISON</span>
                <br />
                ÉCOLOGIQUE
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            paddingLeft: "300px",
            paddingRight: "300px",
            marginTop: "100px",
          }}
        >
          <div
            style={{
              borderTop: "5px dotted #fff",
              height: "100px",
              width: "100%",
            }}
          ></div>
        </Grid>

        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            xs={8}
            item
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              // paddingLeft: "50px",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "100%", paddingLeft: "40px" }}>
              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  fontSize: "45px",
                  fontWeight: "900",
                  color: "#ee6931",
                  // textAlign: "justify",
                }}
              >
                LA MARKETPLACE FRANK
                <span
                  style={{ fontFamily: "Raleway-Italic", color: "#3026f1" }}
                >
                  ,<br />
                  POUR LES PARTICULIERS
                  <br />
                  COMME POUR LES PROFESSIONNELS
                </span>
              </p>
            </div>

            <div style={{ display: "flex", columnGap: "60px" }}>
              <div className="image-container-one">
                <div className="image-container">
                  <img
                    src={imageParticular}
                    alt="Particulier"
                    className="background-image"
                  />
                  <div className="gradient-overlay">
                    <h2
                      style={{
                        textAlign: "left",
                        padding: "10px",
                        fontFamily: "Raleway-Italic",
                        fontWeight: "900",
                      }}
                    >
                      PARTICULIERS
                    </h2>
                  </div>
                </div>
                <div style={{ fontWeight: "600" }} className="text-container">
                  <p>
                    Avec Frank, vous pourrez bientôt mettre en vente vos fonds
                    de tiroirs et opter pour une livraison verte, à prix
                    réduits.
                  </p>
                  <p>
                    Notre envie ? Vous permettre de vider vos placards tout en
                    remplissant vos poches !
                  </p>
                </div>
              </div>

              <div className="image-container-one">
                <div className="image-container">
                  <img
                    src={imageProfessional}
                    alt="Particulier"
                    className="background-image"
                  />
                  <div className="gradient-overlay">
                    <h2
                      style={{
                        textAlign: "left",
                        padding: "10px",
                        fontWeight: "900",
                        fontFamily: "Raleway-Italic",
                      }}
                    >
                      PROFESSIONNELS
                    </h2>
                  </div>
                </div>
                <div style={{ fontWeight: "600" }} className="text-container">
                  <p>
                    Et si vous êtes un professionnel, notre service vous
                    permettra de mettre en ligne vos articles en un rien de
                    temps !
                  </p>
                  <p>
                    Le plus ? Avec Frank des ventes assurées et une livraison
                    plus responsable.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                borderTop: "5px dotted #fff",
                // height: "100px",
                width: "100%",
                zIndex: "11111111",
              }}
            ></div>
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-30%",
            zIndex: "-1",
          }}
        >
          <img
            src={imageBannerTwo}
            style={{ width: "100%", objectFit: "cover" }}
            alt="image"
          />
        </div>

        {/* <Grid
          container
          spacing={spacing?.spacing2}
          className="homepage-banner-two"
        >
          <Grid
            xs={12}
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ borderTop: "4px dotted #fff", width: "70%" }}> </div>
          </Grid>

          <Grid
            xs={12}
            item
            style={{ position: "absolute", top: "-620px", zIndex: -1 }}
          >
            <img
              src={imageBannerTwo}
              style={{ width: "100%", objectFit: "cover" }}
              alt="image"
            />
          </Grid>
        </Grid> */}

        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            paddingLeft: "200px",
            paddingRight: "200px",
          }}
        >
          <Grid xs={12} item>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontSize: "45px",
                fontWeight: "900",
                textAlign: "center",
                color: "#3026f1",
              }}
            >
              FRANK, C'EST AUSSI L'ALTERNATIVE
              <br />
              DE LIVRAISON POUR LES SITES
              <br /> E-COMMERCE
            </p>
          </Grid>

          <Grid
            xs={12}
            md={6}
            item
            style={{ fontFamily: "Raleway-Variable", paddingRight: "20px" }}
          >
            <p style={{ fontWeight: "600" }}>
              Frank offre un service de livraison éco-responsable, économique et
              flexible pour la vente de vos produits comme pour vos livraisons.
            </p>
            <br />
            <p>
              Frank s’occupe de la collecte, la préparation de commande et même
              la livraison au client final.
            </p>
          </Grid>
          <Grid
            xs={12}
            md={6}
            item
            style={{ fontFamily: "Raleway-Variable", paddingLeft: "20px" }}
          >
            <p>
              Vous pourrez aussi intégrer la solution Frank dans vos systèmes de
              gestion existants via des{" "}
              <span style={{ fontWeight: "600" }}> API</span>. Pour en savoir
              plus contactez nous sur
              <br />{" "}
              <span style={{ fontWeight: "600" }}>
                <a
                  href="mailto:Hello@frankme.com"
                  style={{
                    textDecoration: "none",
                    color: "#000",
                  }}
                >
                  Hello@frankme.com
                </a>
              </span>{" "}
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            background: "#f9c9b3",
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {/* <Grid xs={12} item>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Raleway-Italic",
                fontSize: "45px",
                fontWeight: "900",
                color: "#3026f1",
                padding: "50px",
              }}
            >
              NOS ACTUS
            </p>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              columnGap: "20px",
            }}
          >
            <Grid xs={12} md={4} sm={6} item>
              <div className="image-container-one-for-bottom">
                <div className="image-container-bottom">
                  <img
                    src={trainImage}
                    alt="Particulier"
                    className="background-image-bottom"
                  />
                  <div
                    className="text-container-bottom"
                    style={{ display: "flex", padding: "10px 30px 0px 30px" }}
                  >
                    <div style={{ flex: 1, color: "black" }}>
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontWeight: "800",
                          fontSize: "19px",
                        }}
                      >
                        Du Nouveau chez Frank
                      </p>
                      <p
                        style={{
                          fontFamily: "Raleway-italic",
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Il y a 10 heures
                      </p>
                    </div>
                    <div>
                      <ArrowForward
                        style={{
                          color: "#3026f1",
                          background: "#f9c9b3",
                          borderRadius: "100%",
                          padding: "3px",
                          fontSize: "26px",
                          fontWeight: "700",
                        }}
                      />
                    </div>
                  </div>
                  <br />
                  <div style={{ padding: "0px 30px 30px 30px" }}>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                      adipiscing odio et bibendum duis. Auctor scelerisque
                      tellus tortor euismod...
                    </p>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid xs={12} md={4} sm={6} item>
              <div className="image-container-one-for-bottom">
                <div className="image-container-bottom">
                  <img
                    src={boxImage}
                    alt="Particulier"
                    className="background-image-bottom"
                  />
                  <div
                    className="text-container-bottom"
                    style={{ display: "flex", padding: "10px 30px 0px 30px" }}
                  >
                    <div style={{ flex: 1, color: "black" }}>
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontWeight: "800",
                          fontSize: "19px",
                        }}
                      >
                        Du Nouveau chez Frank
                      </p>
                      <p
                        style={{
                          fontFamily: "Raleway-italic",
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Il y a 2 jours
                      </p>
                    </div>
                    <div>
                      <ArrowForward
                        style={{
                          color: "#3026f1",
                          background: "#f9c9b3",
                          borderRadius: "100%",
                          padding: "3px",
                          fontSize: "26px",
                          fontWeight: "700",
                        }}
                      />
                    </div>
                  </div>
                  <br />
                  <div style={{ padding: "0px 30px 30px 30px" }}>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                      adipiscing odio et bibendum duis. Auctor scelerisque
                      tellus tortor euismod...
                    </p>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid xs={12} md={4} sm={6} item>
              <div className="image-container-one-for-bottom">
                <div className="image-container-bottom">
                  <img
                    src={imageParticular}
                    alt="Particulier"
                    className="background-image-bottom"
                  />
                  <div
                    className="text-container-bottom"
                    style={{ display: "flex", padding: "10px 30px 0px 30px" }}
                  >
                    <div style={{ flex: 1, color: "black" }}>
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontWeight: "800",
                          fontSize: "19px",
                        }}
                      >
                        Du Nouveau chez Frank
                      </p>
                      <p
                        style={{
                          fontFamily: "Raleway-italic",
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Il y a 3 jours
                      </p>
                    </div>
                    <div>
                      <ArrowForward
                        style={{
                          color: "#3026f1",
                          background: "#f9c9b3",
                          borderRadius: "100%",
                          padding: "3px",
                          fontSize: "26px",
                          fontWeight: "700",
                        }}
                      />
                    </div>
                  </div>
                  <br />
                  <div style={{ padding: "0px 30px 30px 30px" }}>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                      adipiscing odio et bibendum duis. Auctor scelerisque
                      tellus tortor euismod...
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
          </div>
          <Grid
            xs={12}
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}
          >
            <div style={{ borderTop: "2px solid #fff", width: "70%" }}> </div>
          </Grid> */}

          <Grid xs={12} item style={{ marginTop: "30px" }}>
            <div style={{ position: "relative" }}>
              <div style={{ position: "absolute", top: "20%", left: "47%" }}>
                <p
                  style={{
                    fontFamily: "Raleway-Italic",
                    color: "#fff",
                    fontSize: "34px",
                    fontWeight: "800",
                  }}
                >
                  Téléchargez l'application mobile <br />
                  Frank
                </p>
                <p
                  style={{
                    fontFamily: "Raleway-Italic",
                    color: "#fff",
                    fontSize: "22px",
                  }}
                >
                  Rejoignez gratuitement la communauté <br /> de livraison verte
                </p>
              </div>
              <img
                src={bottomAction}
                style={{
                  width: "100%",
                  height: "400px",
                  objectFit: "contain",
                }}
                alt=""
              />
              <div className="app-store-play-store-btn">
                <a
                  href="https://apps.apple.com/app/frank/id6502341979"
                  target="_blank"
                >
                  <div>
                    <img
                      src={appStoreFrench}
                      className="app-store-btn"
                      alt=""
                    />
                  </div>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.frankc2c"
                  target="_blank"
                >
                  <div>
                    <img
                      src={googlePlayFrench}
                      className="play-store-btn"
                      alt=""
                    />
                  </div>
                </a>
              </div>
            </div>
          </Grid>

          <Grid
            xs={12}
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}
          >
            <div style={{ borderTop: "2px solid #fff", width: "70%" }}> </div>
          </Grid>

          <Grid xs={12} item style={{ height: "200px" }}>
            <ul style={{ padding: "20px 20px 10px 20px" }} className="menu-bar">
              <li style={{ paddingTop: "10px" }}>
                <a href="/">
                  <FrankIcon height={60} fill={defaultLabelColors.color_i} />
                </a>
              </li>
              <li>
                <a className="nav-link" style={{ color: "#ee6931" }} href="/">
                  Accueil
                </a>
              </li>
              <li>
                <a className="nav-link" href="/">
                  Particulier
                </a>
              </li>
              <li>
                <a className="nav-link" href="/">
                  Transporteur
                </a>
              </li>
              <li>
                <a className="nav-link" href="/">
                  Déménagement
                </a>
              </li>
              <li>
                <a className="nav-link" href="/">
                  Market place
                </a>
              </li>
              <li>
                <a className="nav-link" href="/">
                  Stockage colis
                </a>
              </li>
              <li>
                <a className="nav-link" href="/">
                  Blog
                </a>
              </li>

              <div
                style={{
                  color: "#ee6931",
                  fontFamily: "Raleway-Italic",
                  fontWeight: "700",
                  paddingLeft: "10px",
                }}
              >
                <p style={{ fontFamily: "Raleway-Italic" }}>
                  <a
                    href="/about-us"
                    style={{
                      textDecoration: "none",
                      color: "#ee6931",
                      fontFamily: "Raleway-Italic",
                    }}
                  >
                    {" "}
                    Qui sommes-nous ?
                  </a>
                  <br />
                  Nous Contacter :
                </p>
                <p style={{ color: "#3026f1", fontFamily: "Raleway-Italic" }}>
                  <a
                    href="mailto:Hello@frankme.com"
                    style={{
                      textDecoration: "none",
                      fontFamily: "Raleway-Italic",
                    }}
                  >
                    → hello@frankme.com
                  </a>
                </p>
              </div>
            </ul>
            <div
              style={{
                fontFamily: "Raleway-Italic",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a
                href="/terms-and-condition"
                style={{
                  textDecoration: "none",
                  fontFamily: "Raleway-Italic",
                  fontWeight: "500",
                  color: "#ee6931",
                }}
              >
                {" "}
                Termes et conditions
              </a>{" "}
              &nbsp; | &nbsp;
              <a
                href="/privacy-policy"
                style={{
                  textDecoration: "none",
                  fontFamily: "Raleway-Italic",
                  fontWeight: "500",
                  color: "#ee6931",
                }}
              >
                {" "}
                Politique de confidentialité
              </a>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="mobile-screen">
        <MobileView />
      </div>
    </>
  );
}

export default LandingPage;
